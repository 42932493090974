import React from 'react';
import './ZoomTextCta.scss';
import { getSocialBrandKitStyle } from '../../../../libs/helpers';
import SayCheeseCta from '../../SayCheeseCta/SayCheeseCta';

class ZoomTextCta extends React.Component {

    constructor (props) {
        super(props);
    }

    render () {
        const { backgroundColor, color } = getSocialBrandKitStyle();
        const qrSize = this.props.isVertical ? 300 : 420;

        return (
            <div className="ZoomTextCta" style={{ backgroundColor, color }}>
                <div className="desc">
                    <SayCheeseCta qrSize={qrSize} onError={this.props.onError}/>
                </div>
                <div className="pwby-logo-container">
                    <div className="pwby-logo"/>
                </div>
            </div>
        );
    }
}

export default ZoomTextCta;