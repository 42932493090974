import { $_GET, setBlackBackground, setFitToScreen, setOverscan } from '../Upshow';
import UpshowLogger from '../Logger';
import setDebugMode from '../components/Debug';

const SettingsService = {
    settings: { cta: '', tz: 'America/Chicago' },

    loadSettings: function (settings = {}) {
        SettingsService.settings = settings;
        SettingsService.settings.account_name = settings.title;

        setOverscan(SettingsService.hasTrueUiSetting('overscan'));
        setFitToScreen(SettingsService.hasTrueUiSetting('fittoscreen'));
        setBlackBackground(SettingsService.hasTrueUiSetting('blackbackground'));
        setDebugMode(SettingsService.hasTrueUiSetting('debug_mode'));
        UpshowLogger.debug_level = SettingsService.getUiSetting('debug_level') ?? 0;

        if (window.UPshowAndroidApp) UPshowAndroidApp.setDeviceId(SettingsService.getSetting('device_id'));
    },

    getCta: () => {

        const cta = {};

        cta.slim_cta = SettingsService.hasTrueUiSetting('slim_cta');

        cta.animated_cta = !SettingsService.hasTrueUiSetting('no_moving_cta');

        cta.alt_cta = SettingsService.hasSetting('alt_cta') ? SettingsService.getSetting('alt_cta') : false;

        return cta;
    },

    hasSetting: (key) => {
        return !!SettingsService.settings[key];
    },

    hasTrueSetting: (key) => {
        const setting = SettingsService.getSetting(key);
        return !!setting && 'false' !== setting && 'FALSE' !== setting;
    },

    getSetting: (key, defaultValue) => {
        const value = SettingsService.settings[key];
        return value === undefined ? defaultValue : value;
    },

    hasUiSetting: (key) => {
        return !!SettingsService.getUiSetting(key);
    },

    hasTrueUiSetting: (key) => {
        const setting = SettingsService.getUiSetting(key);
        try {
            return JSON.parse(setting) === true || JSON.parse(setting) === 1;
        } catch (e) {
            return false;
        }
    },

    getUiSetting: (key, defaultValue) => {
        return SettingsService._getUiSettingFromUrl(key, SettingsService._getUiSettingFromConfig(key, defaultValue));
    },

    getJsonUiSetting: (key, defaultValue) => {
        const value = SettingsService._getUiSettingFromUrl(key, SettingsService._getUiSettingFromConfig(key, defaultValue));

        if (typeof (value) === 'object') {
            return value;
        } else if (value) {
            try {
                return JSON.parse(value);
            } catch (e) {
                return defaultValue;
            }
        }

        return defaultValue;
    },

    _getUiSettingFromConfig: (key, defaultValue) => {
        const uiSettings = SettingsService.getSetting('ui_settings');
        if (!uiSettings) return defaultValue;

        return typeof uiSettings[key] === 'undefined' ? defaultValue : uiSettings[key];
    },

    _getUiSettingFromUrl: (key, defaultValue) => {
        const processedSetting = SettingsService._processGetUiSetting($_GET[`ui.${key}`]);

        return typeof processedSetting === 'undefined' ? defaultValue : processedSetting;
    },

    _processGetUiSetting: (value) => {
        if (typeof value !== 'string') {
            return value;
        }

        if (value.substr(0, 5) === 'json:') {
            try {
                return JSON.parse(value.substr(5));
            } catch (e) {
                console.error(e);
                UpshowLogger.error(['SettingsService', 'json_parse'], `Error parsing url GET json setting ${value}`);
                return undefined; //can't parse so it's undefined
            }
        }

        return value;
    },

    hasFeature: (key) => !!SettingsService.settings.features?.[key],

    getTimezone: () => SettingsService.settings.tz || 'America/Chicago',

    getBrandKit: () => SettingsService.getSetting('brandKit')
};

export default SettingsService;
