import React, { useEffect, useState } from 'react';
import Qr from './Qr';
import axios from 'axios';
import { getToken } from '../../../Upshow';
import UPshowLogger from '../../../Logger';
import './SayCheeseCta.scss';
import { Textfit } from 'react-textfit';
import SettingsService from '../../../services/SettingsService';
import { getSocialBrandKitStyle } from '../../../libs/helpers';
import { StatePreconditionError } from '../../../states/Errors';
import ScreenService from '../../../services/ScreenService';

const TOKEN_URL = `${process.env.REACT_APP_SAY_CHEESE_SERVER_URL}/getToken`;

function SayCheeseCta({ qrSize, fullScreen, onError, onLoad, customClass = '' }) {
    const isVertical = ScreenService.isVertical;
    const disableDirectShare = !SettingsService.hasTrueSetting('direct_share');
    const [token, setToken] = useState(null);
    const [cta, setCta] = useState('get on this screen!');

    useEffect(() => {
        async function init () {
            try {
                const { data } = await axios.get(TOKEN_URL, {
                    headers: {
                        'x-access-token': getToken()
                    }
                });
                if (!data.token) {
                    UPshowLogger.error('SayCheese', 'Error on Say Cheese no token');
                    if (onError) onError(new StatePreconditionError('Error calling Say Cheese server' + e.message));
                }
                const settingCta = SettingsService.getCta();
                if (settingCta.alt_cta) setCta(settingCta.alt_cta);
                setToken(data.token);
                if (onLoad) onLoad();
            } catch (e) {
                UPshowLogger.error('SayCheese', 'Error calling Say Cheese server' + e.message);
                if (onError) onError(new StatePreconditionError('Error calling Say Cheese server' + e.message));
            }
        }

        init();
    }, []);

    if (!token) return null;

    const { logo, backgroundColor, color } = getSocialBrandKitStyle();
    const qrHorizontalStyle = fullScreen ? { borderLeft: `2px solid ${color}` } : { borderBottom: `2px solid ${color}` };
    const qrVerticalSyle = fullScreen ? { borderTop: `2px solid ${color}` } : { borderBottom: `2px solid ${color}` };

    const getContent = () => (
        <div className={`saycheese-cta-container ${customClass ? customClass : ''} ${isVertical ? 'vertical' : ''}`}>
            <div className="header">
                {logo && <div className="sc-logo-container">
                    <img src={logo} className="sc-cta-logo"/>
                </div>}
                <div className="sc-cta-container">
                    <Textfit
                        className={'CtaText'}
                        max={148}>
                        {disableDirectShare ? 'Get on this screen!' : `Scan the code and ${cta}`}
                    </Textfit>
                </div>
               
            </div>
            <div className="qr-container" style={isVertical ? qrVerticalSyle : qrHorizontalStyle}>
                { !disableDirectShare && <Qr token={token} size={qrSize}/> }

                {fullScreen && <div className="pwby-logo"/>}
            </div>
        </div>
    )

    if (fullScreen) {
        return <div className="saycheese-full-screen" style={{backgroundColor, color}}>
            {getContent()}
        </div>
    }

    return getContent();
}

export default SayCheeseCta;
