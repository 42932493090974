import React from 'react';
import PropTypes from 'prop-types';

import './DefaultCta.scss';
import { getSocialBrandKitStyle } from '../../../libs/helpers';
import ScreenService from '../../../services/ScreenService';
import SayCheeseCta from '../SayCheeseCta/SayCheeseCta';

class DefaultCta extends React.Component {

    constructor (props) {
        super(props);
        this.isVertical = ScreenService.isVertical;
    }

    render () {
        const { relaxed, hidePowered } = this.props;
        const { backgroundColor, color } = getSocialBrandKitStyle();
        const qrSize = 350;
        const customClass = `small ${relaxed ? 'relaxed' : ''}`;
        
        return (
            <div className={`Cta DefaultCta ${this.isVertical ? 'vertical' : ''}`} style={{backgroundColor, color}}>
                <SayCheeseCta qrSize={qrSize} onError={this.props.onError} customClass={customClass} />
                {!hidePowered && <div className="pwby-logo"/>}
            </div>
        );
    }
}

DefaultCta.propTypes = {
    hidePowered: PropTypes.bool,
    relaxed: PropTypes.bool,
};

DefaultCta.defaultProps = {
    hidePowered: false,
    relaxed: false,
};

export default DefaultCta;